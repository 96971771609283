<template>
  <v-container fluid class="mb-10">
    <v-row style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-row class="pa-10 pt-5">
            <v-col cols="12">
              <v-card class="mb-10" elevation="0">
                <v-toolbar
                  elevation="0"
                  style="background: #f5f5f5"
                  height="100px"
                >
                  <div>
                    <p class="mb-0 text-h4 font-weight-bold">Expense Details</p>
                    <p class="mb-0 text-h4 font-weight-bold">
                      Start Date :
                      <span class="font-weight-regular">{{
                        this.$route.query.start_date
                      }}</span>
                    </p>
                    <p class="mb-0 text-h4 font-weight-bold">
                      End Date :
                      <span class="font-weight-regular">{{
                        this.$route.query.end_date
                      }}</span>
                    </p>
                  </div>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text class="pa-0">
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="itemsWithIndex"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template v-slot:item.time="{ item }">
                          <!-- {{ new Date(item.created_at).toDateString() }} -->
                          10/12/2021
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      menu1: false,
      menu2: false,
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),

      headers: [
        { text: "S/N", value: "index", sortable: false },
        { text: "Expense Name", value: "expense_name", sortable: false },
        { text: "Amount", value: "expense_amount" },
      ],
      reportInfo: {
        expenses: [],
      },
    };
  },
  computed: {
    itemsWithIndex() {
      return this.reportInfo.expenses.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  methods: {
    getReport() {
      axios
        .post(`productdetails/get_report/`, {
          start_date: this.$route.query.start_date,
          end_date: this.$route.query.end_date,
          is_monthly: this.$route.query.is_monthly,
        })
        .then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.reportInfo.expenses = response.data.expenses;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },

  created() {
    this.getReport();
  },
};
</script>


<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>